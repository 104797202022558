import React from 'react'
import styled from 'styled-components';
import { colors } from '../style/colors';

const Container = styled.div`
    padding: 0 10% 0 0;

    @media (max-width: 850px) {
        padding: 20px;
    }
`;
const ImageContainer = styled.div`
    /* background: ${colors.light}; */
    margin-bottom: 30px;
    /* height: 120px; */
`;
const Image = styled.img`
    margin: 0;
`;
const Heading = styled.h5`
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 550px) {
        font-size: 22px;
    }
`;
const Copy = styled.p`
    font-size: 18px;
    line-height: 1.6;
    max-width: 90%;
`;

const ServiceItem = ({ heading, icon, description }) => {
    return (
        <Container>
            <ImageContainer>
                <Image src={icon}/>
            </ImageContainer>
            <Heading>{heading}</Heading>
            <Copy>{description}</Copy>
        </Container>
    )
}

export default ServiceItem