import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout';
import { SplitPane } from '../layout/containers';
import { ServiceList } from '../layout/lists';
import ServiceItem from '../components/service-item';
import { NavTitle } from '../style/typography';
import SEO from '../components/seo';

const NavContainer = styled.section`
    padding: 0 10%;
    position: sticky;
`;
const Copy = styled.p`
    font-size: 16px;
    padding-right: 10%;
`;

const ServicesPage = () => {

    
    const _renderServices = () => {
        let renderItems = []
        for (let i=0; i<serviceData.length; i++) {
            const service = serviceData[i]
            renderItems.push(<ServiceItem icon={require(`../images/icons/${i + 1}.svg`)} heading={service.title} description={service.description} />)
        }

        return renderItems
    }

    return (
        <Layout>
            <SEO title="Services" />
            <SplitPane>
                <NavContainer>
                    <div style={{position: 'sticky', top: 120}}>
                        <NavTitle>Our Services</NavTitle>
                        <Copy>We have expanded our initial offerings of civil and structural engineering to provide a broad range of services within the building industry. Our collaborators and clients span homeowners to the luxury tourism industry to governments and other institutions.</Copy>

                    </div>
                </NavContainer>
                <ServiceList>
                    {_renderServices()}
                </ServiceList>
            </SplitPane>
        </Layout>
    )
}

export default ServicesPage

const serviceData = [
    {
        title: "Structural",
        description: "Whether a car park’s post-tensioned suspended flooring system or the complex geometries of a hillside residence, Beston’s careful assessment of each structural engineering challenge leads us to creative and cost-effective solutions that reduce waste and are built to last."
    },
    {
        title: "Marine & Coastal",
        description: "We have extensive experience evaluating and engineering for tricky coastal sites. Wherever possible, we favour solutions that work with the landscape, instead of fighting against it. This approach has led us to the winning combination of sophisticated solutions and reduced environmental impact."
    },
    {
        title: "Roads",
        description: "When Beston designs roads, we are not only seeking the best engineering solutions, but also the ways in which we can manage traffic and minimise interference on the site in order to keep people and things moving safely along."
    },
    {
        title: "Water",
        description: "A major step in Beston taking projects from beautifully designed to buildable is our development of complex drainage systems to ensure that the way we engineer a site keeps it dry through construction, and well drained through adverse weather."
    },
    {
        title: "Project Management",
        description: "Vigilant with quality control, Beston has developed a rigorous system of checks and accountability to make sure our management of projects keeps them on schedule and on budget. A key component of this? Our agility in solving challenges as they arise."
    },
    {
        title: "Bigger Picture",
        description: "Beston has gained its reputation through our ability to adapt to changes in the industry. We are committed to learning and adopting new technology. In addition to our core services, we have added construction management and project financing to our expanding list of capabilities."
    },
]